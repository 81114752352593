import React, { Fragment, useState, useEffect } from 'react'
import Pagination from 'react-js-pagination'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css';
import { Link } from 'react-router-dom'
import MetaData from './layout/MetaData'
import Product from './product/Product'
import Loader from './layout/Loader'
import Slide from "react-reveal"
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert';
import { getProducts } from '../actions/productActions'
import { Helmet } from 'react-helmet';
import  Pdsp  from '../productshop';




const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range)

const Home = ({ match }) => {

    const [currentPage, setCurrentPage] = useState(1)
    const [price, setPrice] = useState([1, 10])
    const [category, setCategory] = useState('')
    const [rating, setRating] = useState(0)

    const categories = [
 'Menu',
        'Seul',
        'Boisson',
        'Frites',
        'Sauce'
    ]
    function ListSection({ title, Pdsp, col }) {
        return (
          <>
       
          <div className="sand col-sm-12">
            <h2>{title}</h2>
            <ul className='row'>
              {Pdsp.map(product =>
           <div className={`col-sm-12 col-md-3 col-lg-${col} my-3`}>
           <div className="card p-3 rounded">
               <img
                   className="card-img-top mx-auto"
                   src={product.images[0].url}
               />
               <div className="card-body d-flex flex-column">
                   <h5 className="card-title">
                       <Link to={`/product/${product._id}`}>{product.name}</Link>
                   </h5>
                   <div className="ratings mt-auto">
                       <div className="rating-outer">
                           <div className="rating-inner" style={{ width: `${(product.ratings / 5) * 100}%` }}></div>
                       </div>
                       <span id="no_of_reviews">({product.numOfReviews} Avis)</span>
                   </div>
                   <p className="card-text">{product.price}€</p>
                   <Link to={`/product/${product._id}`} id="view_btn" className="btn btn-block">VOIR</Link>
               </div>
           </div>
       </div>
              )}
            </ul>
          </div>
          </>
         
        );

      }

    const Seul = Pdsp.filter(product =>
        product.category === 'Seul'
      );
     const Menu = Pdsp.filter(product =>
        product.category === 'Menu'
      );
    

    
    
    const alert = useAlert();
    const dispatch = useDispatch();

    const { loading, products, error, productsCount, resPerPage, filteredProductsCount } = useSelector(state => state.products)

    const keyword = match.params.keyword

    useEffect(() => {
        if (error) {
            return alert.error(error)
        }

        dispatch(getProducts(keyword, currentPage, price, category, rating));


    }, [dispatch, alert, error, keyword, currentPage, price, category, rating])

    function setCurrentPageNo(pageNumber) {
        setCurrentPage(pageNumber)
    }

    let count = productsCount;
    if (keyword) {
        count = filteredProductsCount
    }

    return (<><Helmet><title>Ardayco</title>
    <meta name='description' content='art painting shop toile tableau'></meta>
    <link rel='canonical' href='/'></link></Helmet>
        <Fragment>

            {loading ? <Loader /> : (
                <Fragment>
                    <MetaData title={'Ardayco-Shop'} />
                    <nav class="menu navbar navbar-expand-lg navbar-light bg-light" id='top'>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <p className='menu'>---MENU---</p>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item col-4 nv" href="#tous"><p>TOUS</p></a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item col-4 nv" href="#seul"><p>SANDWICHS SEULS</p></a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item col-4 nv" href="#menus"><p>MENUS</p></a>
          <div class="dropdown-divider"></div>
        </div>
      </li>
  
    </ul>

  </div>
</nav>
                    
<div className='vide'>.</div>
<Slide right><div className='gro pubc row'><img className='pub'src='/images/Menukebab.png'></img><div><h1>VENEZ GOUTER NOS SPECIALITES</h1></div></div></Slide>
                    <Slide left><h1><a id="tous">TOUS</a></h1></Slide>

                    <section id="products">
                        <ul className="row">

                            {keyword ? (
                                <Fragment>
                                   <div className="col-6 col-md-3 mt-5 mb-5">
                                        <div className="px-5">
                                            <Range
                                                marks={{
                                                    1: `1`,
                                                    10: `10€`
                                                }}
                                                min={1}
                                                max={10}
                                                defaultValue={[1, 10]}
                                                tipFormatter={value => `${value}€`}
                                                tipProps={{
                                                    placement: "top",
                                                    visible: true
                                                }}
                                                value={price}
                                                onChange={price => setPrice(price)}
                                            />

                                            <hr className="my-5" />

                                            <div className="mt-5">
                                                <h4 className="mb-3">
                                                    Categories
                                                </h4>

                                                <ul className="pl-0">
                                                    {categories.map(category => (
                                                        <li
                                                            style={{
                                                                cursor: 'pointer',
                                                                listStyleType: 'none'
                                                            }}
                                                            key={category}
                                                            onClick={() => setCategory(category)}
                                                        >
                                                            {category}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <hr className="my-3" />

                                            <div className="mt-5">
                                                <h4 className="mb-3">
                                                 Avis
                                                </h4>

                                                <ul className="pl-0">
                                                    {[5, 4, 3, 2, 1].map(star => (
                                                        <li
                                                            style={{
                                                                cursor: 'pointer',
                                                                listStyleType: 'none'
                                                            }}
                                                            key={star}
                                                            onClick={() => setRating(star)}
                                                        >
                                                            <div className="rating-outer">
                                                                <div className="rating-inner"
                                                                    style={{
                                                                        width: `${star * 20}%`
                                                                    }}
                                                                >
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                    
              
                                    <div className="col-6 col-md-9">
                                    
                                        <ul className="row">
                                            {products.map(product => (
                                               <Product key={product._id} product={product} col={4} />
                                            ))}
                                        </ul>
                                    </div>
                                </Fragment>
                            ) : (
                                    products.map(product => (
                                        <Product key={product._id} product={product} col={3} />
                                    ))
                                )}

                        </ul>
                       
                        <Slide left><div className='gro pubc row'><div><h1>ENVIE D'UN SANDWICH SEUL ??</h1></div><img className='pub'src='/images/hamburger.png'></img></div></Slide>                
                        <div className='seul row'>
         <h1><a id="seul">SANDWICHS SEULS</a></h1>
         <ListSection
          
           Pdsp={Seul}
         /></div>
         <Slide right><div className='gro pubc row'><img className='pub'src='/images/MenuSandwichPoulet.png'></img><div><h1>UN MENU COMPLET ??</h1></div></div></Slide>   
           <div className='menus row'>
            <h1><a id="menus">MENUS</a></h1>
         <ListSection
           Pdsp={Menu}
         />
       </div>
       <h1><a href="#top" ><p>RETOUR ACCUEIL</p></a></h1>
                    </section>
                    <Slide right><div className='pubc row'><img className='pub'src='/images/libanais.png'></img><img className='pub'src='/images/hamburger.png'></img></div></Slide>
                    {resPerPage <= count && (
                        <div className="d-flex justify-content-center mt-5">
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={resPerPage}
                                totalItemsCount={productsCount}
                                onChange={setCurrentPageNo}
                                nextPageText={'Next'}
                                prevPageText={'Prev'}
                                firstPageText={'First'}
                                lastPageText={'Last'}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                    )}

                </Fragment>
            )}

        </Fragment></>
    )
}

export default Home
